import {createTheme, CssBaseline, GlobalStyles, ThemeProvider} from '@mui/material';
import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {GLOBAL_STYLES, THEME_OPTIONS} from './utils/styles/constants';
import {csCZ} from "@mui/material/locale";
import {csCZ as gridCsCZ} from '@mui/x-data-grid';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import csLocale from "date-fns/locale/cs";
import {getKcContext} from "keycloakify";
import MessagesProvider from "./utils/messages/MessagesProvider";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import queryClient from "./services/queryClient";

const App = lazy(()=>import("./App"));
const KcApp = lazy(()=>import("./containers/keycloak/KcApp"));

const { kcContext } = getKcContext();
/*
TEST

getKcContext({
    "mockPageId": "login.ftl"
});
 */

const inputGlobalStyles = <GlobalStyles styles={GLOBAL_STYLES} />;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
      {inputGlobalStyles}
      <CssBaseline />
      <ThemeProvider theme={createTheme(THEME_OPTIONS, csCZ, gridCsCZ)}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale} localeText={{
          cancelButtonLabel: "Zrušit"
        }}>
          <MessagesProvider>
              <QueryClientProvider client={queryClient}>
                <Suspense>
                  {
                    kcContext===undefined
                      ? <App />
                      : <KcApp kcContext={kcContext} />
                  }
                </Suspense>
              </QueryClientProvider>
          </MessagesProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
