import {css, ThemeOptions} from '@mui/material';
import {Shadows} from '@mui/material/styles/shadows';

const MAIN_COLORS = {
    primary: '#0056BC',
    secondary: '#00A3DE'
}

export const THEME_OPTIONS: ThemeOptions = {
  palette: {
    primary: {
      main: MAIN_COLORS.primary,
    },
    secondary: {
      main: MAIN_COLORS.secondary,
      contrastText: '#ffffff',
    }
  },
  shadows: Array(25).fill("none") as Shadows,
  components: {
    MuiMenu: {
        styleOverrides: {
            paper: css`
                border: 1px solid ${MAIN_COLORS.primary};
            `,
            root: css`
                & .MuiMenuItem-root {
                    color: ${MAIN_COLORS.primary};
                }
            `
        }
    }
  }
};

export const GLOBAL_STYLES = css`
    html {
      height: 100%;
    }
  
    body {
        margin: 0;
        position: relative;
        min-height: 100%;
        display: flex;
    }

    #root {
      width: 100%;
    }
`;