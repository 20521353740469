import {FC, ReactNode} from "react";
import {SnackbarProvider} from "notistack";

interface Props {
    children?: ReactNode;
}

const MessagesProvider = (
    {
        children
    }: Props
) => {
    return (
        <SnackbarProvider>
            {children}
        </SnackbarProvider>
    );
};

export default MessagesProvider;